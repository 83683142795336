import React, { useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./widget.module.css";
import { MdArrowForwardIos, MdArrowBackIosNew } from "react-icons/md";
import VoiceocLogo from "../../assets/voiceoc_logo.png";
import { rootUrl } from "../WebBotApi/webBotApi";

const DoctorNamesWidget = ({
  botresponse,
  sendChatToSocket,
  setDoctorWidget,
  doctorArr,
}) => {
  const [scrollX, setscrollX] = useState(0); // For detecting start scroll postion
  const [scrolEnd, setscrolEnd] = useState(false);

  const [doctorImg, setDoctorImg] = useState('')
  // const [doctorImg, setDoctorImg] = useState('')

  const openDoctorModal = (doctorUrl) => {
    try {
      setDoctorImg(doctorUrl)
      document.getElementById("doctorModal")?.showModal()

    } catch (error) {
      console.error(error)
    }
  }

  const scroll = (offset) => {
    ref.current.scrollLeft += offset;
    setscrollX(scrollX + offset);
    // ref.current.scrollTo({
    //   x: 100,
    //   y: 100,
    // });
  };
  const ref = useRef();
  const arr = [{}, {}, {}, {}];
  return (
    <div style={{ position: "relative" }}>
      <MdArrowBackIosNew
        color="grey"
        className={styles.backArrow}
        size={20}
        onClick={() => scroll(-250)}
      />
      <MdArrowForwardIos
        color="grey"
        className={styles.forwardArrow}
        size={20}
        onClick={() => scroll(250)}
      />
      <div className={styles.container} ref={ref}>
        {/* <Slider {...settings}> */}
        {doctorArr.map((item, index) => (
          <div
            className={styles.slide}
            style={{ overflow: 'hidden' }}
          >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} onClick={() => openDoctorModal(item?.image_url ? item?.image_url : `${rootUrl}/content/default_webbot_doctor.jpg`)}>
              <img src={item?.image_url ? item?.image_url : `${rootUrl}/content/default_webbot_doctor.jpg`} alt="" className="img-fluid" style={{ maxWidth: '100%',maxHeight:'250px' }} />
            </div>
            <div className={styles.head}

              onClick={() => {
                openDoctorModal(item?.image_url ? item?.image_url : `${rootUrl}/content/default_webbot_doctor.jpg`)
              }}

            >{item?.name_en}</div>
            <div className={`${styles.head} backendColor`}
              onClick={() => {
                sendChatToSocket(`${(index + 1)}`, item?.name_en);
                setDoctorWidget();
              }}

            ><span className={styles.bookAppoinmentButton}>Book Appointment</span></div>
            {/* <pre>{JSON.stringify(item, null, 2)}</pre> */}
            {/* <p className={styles.desc}>
              <span>Fees</span>: 22220
            </p>
            <p className={styles.desc}>
              <span>Specialist</span>: Heart Problems
            </p>
            <p className={styles.desc}>
              <span>Working</span>: Monday-saturday
            </p>
            <p className={styles.desc}>
              <span>Timing</span>: 11am to 9pm
            </p> */}
          </div>
        ))}
      </div>

      <dialog id="doctorModal" className={styles.doctorModal}>
        <form method="dialog" className={styles.dialogFlexColumn}>
          <img src={doctorImg} alt="" className="img-fluid" style={{ maxWidth: '100vw', maxHeight: '100vh', width: "40vw", paddingBottom: 20, borderRadius: 5 }} />
          <button className={styles.time_button}>Close</button>
        </form>
      </dialog>

    </div>
  );
};

export default DoctorNamesWidget;
