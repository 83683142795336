import React, { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import mainstyles from "../WebChatBot.module.css";
import pdfIcon from '../../assets/pdfIcon.webp'
import { BsArrowDownCircleFill } from "react-icons/bs";


export default function PdfPreview({ pdfUrl, pdfName, messageFrom }) {

    const [pdfError, setPdfError] = useState(false)

    const onPdfLoadError = (error) => {
        try {
            console.error(error)
            setPdfError(true)

        } catch (error) {
            console.error(error)
        }
    }



    return (
        <div style={{ flex: 1, width: '100%', height: '100%', boxSizing: 'border-box', }}>
            <a href={pdfUrl} className={`${mainstyles.pdfIconHref} ${(messageFrom == 'user' || messageFrom == 'customer') ? mainstyles.pdfIconHrefUser : ''}`} target="_blank"  >
{/* 
                {
                    pdfUrl && !pdfError && 
                    <div>
                        <Document file={{
                            url: pdfUrl,
                            // cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
                            // cMapPacked: true,

                        }}
                            className={mainstyles.pdfContainer} onLoadSuccess={console.log} onSourceError={console.error} onLoadError={onPdfLoadError} >
                            <Page pageNumber={1} />
                        </Document>
                    </div>
                {/* } */}

                {
                    <div>
                        <a href={pdfUrl} target="_blank" rel="noopener noreferrer">
                        </a>
                    </div>
                }
                <div className={mainstyles.pdfIconContainer} style={{ width: '100%', padding: 5 }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div className={mainstyles.pdfIconContainer}>
                            <img src={pdfIcon} className={mainstyles.pdfIconImg} />
                        </div>
                        <div className={mainstyles.pdfText}>
                            {pdfName}
                        </div>
                        <div className={mainstyles.pdfDownloadIcon} style={{ color: (messageFrom == 'user' || messageFrom =='customer') ? 'white' : 'black' }}>
                            <BsArrowDownCircleFill />
                        </div>

                    </div>
                </div>
            </a>
        </div>
    )
}
