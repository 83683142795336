import React, { useState } from "react";
import styles from "../WebChatBot.module.css";
import { TfiBackRight } from "react-icons/tfi";
import { SlLocationPin } from "react-icons/sl";
import DateWidget from "../widget/dateWidget/DateWidget";
import PdfPreview from "../widget/PdfPreview";

const ResponseFunction = ({
  color,
  botresponse,
  btnFun,
  sendLocation,
  setDateOfBirth,
  showDateWidget,
  showGoogleCordinatesBtn,
  showButtons,
  hideButtons = false,
}) => {
  const text = botresponse.text;
  // console.log(botresponse, "botresponse", showButtons)
  return (
    <div>
      <style>
        {/* #btnActiveStyle:active {
              color: rgba(0, 0, 0, 0.95);
              background-color: ${color};
             border-color: rgb(147, 119, 194);
            } */}
        {`
            `}
      </style>
      <div style={{ display: "flex", alignItems: "center", gap: "7px" }}>
        <div>
          {/* {(botresponse.url || botresponse.media?.[0]?.url) && (
            !(botresponse.media?.[0]?.url?.includes('pdf') || botresponse?.url?.includes('pdf')) ?

              <div>
                <img
                  src={botresponse.media?.[0]?.url || botresponse.url}
                  className={styles.fileMessage}
                  style={{
                    background: "rgb(243, 243, 243)",
                    marginBottom: "10px",
                    borderRadius: 5,
                  }}
                />
                <b>

                  {botresponse.media?.[0]?.url || botresponse.url}
                </b>

              </div>
              :
              <div>
              </div>
          )} */}
          {(botresponse.text?.trim() || botresponse?.caption?.trim() || botresponse.media?.[0]?.url || botresponse?.url) &&
            <div
              className={styles.other}
              id="other"
            // style={{ display: "flex", flexDirection: "column" }}
            >

              {
                (botresponse.media?.[0]?.url || botresponse?.url) ?
                  ((botresponse.media?.[0]?.url?.includes('pdf') || botresponse?.url?.includes('pdf')) ?
                    <PdfPreview pdfUrl={botresponse.media?.[0]?.url || botresponse.url} pdfName={botresponse?.caption || botresponse?.text} />
                    // <a href={botresponse.media?.[0]?.url || botresponse.url}  target="_blank"> {botresponse.media?.[0]?.url || botresponse.url} </a>
                    :
                    <img
                      src={botresponse.media?.[0]?.url || botresponse.url}
                      className={styles.fileMessage}
                      style={{
                        background: "rgb(243, 243, 243)",
                        marginBottom: "10px",
                        // borderRadius: 5,
                      }}
                    />
                  )
                  :
                  null
              }



{
!!(botresponse.text?.trim() || botresponse?.caption?.replaceAll('report.pdf', '').replaceAll('prescription.pdf', '').trim()) &&

              <div style={{ fontWeight: 'bold', padding: "10px 14px" }} dangerouslySetInnerHTML={{
                
                __html: (
                  
                  
                  botresponse.text ? text : botresponse?.caption?.replaceAll('report.pdf', '').replaceAll('prescription.pdf', ''))?.split("*").reduce((acc, el, i) => acc + (i % 2 != 0 ? '<b>' : '</b>') + el, '').split('\n').reduce((acc, el, i) => acc + (i != 0 ? '<br/>' : '') + el, '')
                }}

                >

              </div>
              }


            </div>
          }
        </div>
        {/* {showButtons && (
          <TfiBackRight
            size={18}
            color="grey"
            style={{ cursor: "pointer" }}
            onClick={() => btnFun("back")}
          />
        )} */}
      </div>
      {(botresponse.actionType && !hideButtons) && (
        <div className={styles.btn_div_container}>

          <div
            className={`${styles.btn_div} ${botresponse?.action?.buttons?.length > 20 ? styles.btn_div_scroll : ''}`}
            style={{ display: showButtons ? "flex" : "none" }}
          >
            {botresponse.actionType == "list" &&
              botresponse?.action?.sections[0]?.rows?.map((item, index) => (
                <div
                  className={styles.res_btn}
                  id="btnActiveStyle"
                  onClick={() => {
                    btnFun(item.id, item.title);
                  }}
                >
                  {item.title}
                </div>
              ))}

                       {/* restricted back button */}
                       {botresponse?.actionType === "dr_button" &&
                botresponse?.action?.buttons
                  ?.filter(item => item.reply.id !== 'Back')
                  .map((item) => (
                    <div
                      className={`${styles.res_btn} backendColor`}
                      id="btnActiveStyle"
                      onClick={() => {
                        console.log(item, "itemmmmmm in btn");
                        btnFun(item.reply.id, item.reply.title);
                      }}
                    >
                      {item.reply.title}
                    </div>
                  ))
              }
          </div>
        </div>
      )}

      {showGoogleCordinatesBtn && (
        <div
          className={styles.res_btn}
          id="btnActiveStyle"
          style={{ display: showButtons ? "" : "none", marginTop: "8px" }}
          onClick={() => {
            sendLocation();
          }}
        >
          <SlLocationPin size={18} style={{ marginRight: "8px" }} />
          Share your google cordinates
        </div>
      )}

      {showDateWidget && (
        <div style={{ display: showButtons ? "flex" : "none" }}>
          <DateWidget setDateOfBirth={(date) => setDateOfBirth(date)} />
        </div>
      )}
    </div>
  );
};

export default ResponseFunction;

