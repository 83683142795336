import React, { useState, useEffect } from "react";
import styles from "./setting.module.css";
import { RxCross1 } from "react-icons/rx";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";

const EmailModal = ({ setOpenEmail, sentEmail, setOpenSetting, color }) => {
  const [btn, setBtn] = useState(1);
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);

  const validateEmail = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(email)) {
      setError("Please enter a valid email address");
    } else {
      setError("");
      setBtn(1);
      sentEmail(email);
    }
  };

  return (
    <div className={styles.modalDiv}>
      <div className={styles.headDiv}>
        <div className={styles.back} onClick={() => setOpenEmail()}>
          <MdOutlineKeyboardArrowLeft size={20} color="rgba(0, 0, 0, 0.6)" />
        </div>
        <h2 className={styles.heading}>Email transcript</h2>
      </div>

      <p className={styles.text}>Email</p>
      <input
        type="email"
        value={email}
        className={styles.input}
        onChange={(e) => {
          setEmail(e.target.value);
          console.log(e.target.value, "valllll");
        }}
      />
      {error && <div className={styles.error}>{error}</div>}
      <div>
        <button
          disabled={!email}
          onClick={() => {
            validateEmail();
          }}
          style={{
            background: email ? color : "#dadada",
            cursor: email ? "pointer" : "not-allowed",
          }}
          className={styles.emailBtn}
        >
          Send
        </button>
        <p
          onClick={() => {
            setBtn(2);
            setOpenEmail();
            setOpenSetting();
          }}
          className={styles.cancelEmail}
        >
          Cancel
        </p>
      </div>
    </div>
  );
};

export default EmailModal;
