import React, { useState, useEffect } from "react";
import styles from "./setting.module.css";
import { RxCross1 } from "react-icons/rx";
import { FcCheckmark } from "react-icons/fc";

const LanguageModal = ({
  setOpenLanguageSetting,
  sendChatToSocket,
  setLanguage,
  language,
}) => {
  useEffect(() => {
    console.log(language, "languageee");
  }, [language]);

  return (
    <div className={styles.modalDiv} style={{ height: "97%" }}>
      <div className={styles.headDiv}>
        <h2 className={styles.heading}>Change Language</h2>
        <div className={styles.cross} onClick={setOpenLanguageSetting}>
          <RxCross1 size={18} color="rgba(0, 0, 0, 0.6)" />
        </div>
      </div>

      <div className={styles.content}>
        <div
          className={styles.row}
          onClick={() => {
            console.log("clicked");
            setLanguage(1);
            sendChatToSocket("English");
            setOpenLanguageSetting();
          }}
          style={{ justifyContent: "space-between" }}
        >
          <p className={styles.text}>English</p>
          {language == 1 && <FcCheckmark color={"green"} size={26} />}
        </div>
        <div
          className={styles.row}
          onClick={() => {
            console.log("clicked ara");
            setLanguage(2);
            sendChatToSocket("Arabic");
            setOpenLanguageSetting();
          }}
          style={{ justifyContent: "space-between" }}
        >
          <p className={styles.text}>Arabic</p>
          {language == 2 && <FcCheckmark color={"green"} size={26} />}
        </div>
      </div>
    </div>
  );
};

export default LanguageModal;
