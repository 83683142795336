import { useState } from "react";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateWidget = ({ setDateOfBirth }) => {
  const [startDate, setStartDate] = useState(new Date("1-1-1999"));

  return (
    <DatePicker
      showIcon
      dateFormat="dd/MM/yyyy"
      selected={startDate}
      onChange={(date) => {
        setStartDate(date);
        setDateOfBirth(date);
      }}
      placeholderText="DOB"
      fixedHeight
    />
  );
};

export default DateWidget;
