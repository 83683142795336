import React, { useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./widget.module.css";
import mainstyles from "../WebChatBot.module.css";
import { MdArrowForwardIos, MdArrowBackIosNew } from "react-icons/md";
import VoiceocLogo from "../../assets/voiceoc_logo.png";
import { rootUrl } from "../WebBotApi/webBotApi";

const TimeButtonsWidget = ({
  botresponse,
  sendChatToSocket,
  setShowTimeButtons,
  timeSplit,
  timeArr,
}) => {
  const [scrollX, setscrollX] = useState(0); // For detecting start scroll postion
  const [scrolEnd, setscrolEnd] = useState(false);

  const scroll = (offset) => {
    ref.current.scrollLeft += offset;
    setscrollX(scrollX + offset);
    // ref.current.scrollTo({
    //   x: 100,
    //   y: 100,
    // });
  };
  const ref = useRef();
  const arr = [{}, {}, {}, {}];


  return (
    <>

      {timeSplit ?

        timeArr.map((el, i) => {

          const previousNumber = timeArr.reduce((acc, elx, ind) => acc + (ind < i ? elx?.arr?.length : 0), 0)


          if (!el?.arr?.length) {
            return (<></>)
          }
          return (
            <>
              <div>
              </div>
              <div className={mainstyles.btn_div_container}>
                <h5 className={mainstyles.title}>{el.title}</h5>
                <div
                  className={`${mainstyles.btn_div_alt} `}
                >
                  {el?.arr.map((item, index) => (
                    <div
                      className={mainstyles.res_btn}
                      id="btnActiveStyle"
                      style={{fontSize:"0.8em", textAlign:'center'}}
                      onClick={() => {
                        sendChatToSocket((previousNumber + index + 1).toString(), item);
                      }}
                    >
                      {item}
                    </div>
                  ))}
                </div>
              </div>
            </>
          )
        }
        )
        :
        <div className={mainstyles.btn_div_container}>

          <div
            className={`${mainstyles.btn_div} ${timeArr?.length > 20 ? mainstyles.btn_div_scroll : ''}`}
            style={{ display: "flex" }}
          >
            {timeArr.map((item, index) => (
              <div
                className={mainstyles.res_btn}
                id="btnActiveStyle"
                onClick={() => {
                  sendChatToSocket((index + 1).toString(),item);
                }}
              >
                {item}
              </div>
            ))}
          </div>

        </div>
      }

    </>

  );




  //   return (
  //     <div style={{ position: "relative" }}>
  //       <div className={styles.time_container} style={{ display:'flex', flexDirection:'row', flexWrap:'wrap', height:'unset !important', paddingBottom:10}} ref={ref}>
  //         {/* <Slider {...settings}> */}
  //         {timeArr.map((item, index) => (
  //           <div
  //             className={styles.time_button}
  //             // style={{padding:0, overflow:'hidden'}}
  //             onClick={() => {
  //               sendChatToSocket((index + 1).toString());
  //               setShowTimeButtons();
  //             }}
  //           >
  //             {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection : 'column' }}>
  //               <img src={item?.image_url ? item?.image_url : `${rootUrl}/content/logo.png`} alt="" className="img-fluid" style={{ width: '100%' }} />
  //             </div> */}
  // {item}
  //               {/* <div className={styles.head}></div> */}
  //             {/* <pre>{JSON.stringify(item, null, 2)}</pre> */}
  //             {/* <p className={styles.desc}>
  //               <span>Fees</span>: 22220
  //             </p>
  //             <p className={styles.desc}>
  //               <span>Specialist</span>: Heart Problems
  //             </p>
  //             <p className={styles.desc}>
  //               <span>Working</span>: Monday-saturday
  //             </p>
  //             <p className={styles.desc}>
  //               <span>Timing</span>: 11am to 9pm
  //             </p> */}
  //           </div>
  //         ))}
  //       </div>
  //     </div>
  //   );
};

export default TimeButtonsWidget;
