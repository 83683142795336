import React, { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { BsArrowLeft, BsArrowRight, BsArrowDownCircleFill } from "react-icons/bs";
import styles from './widget.module.css'

export default function CalenderWidget({ calenderArr, sendChatToSocket }) {

    const [currentMonthPrintIndex, setCurrentMonthPrintIndex] = useState(0)
    const [displayMonthArr, setDisplayMonthArr] = useState([])
    // const [dateArr, setDateArr] = useState([])

    useEffect(() => {
        try {
            let tempArr = []
            let months = []
            let finalMonthArr = []


            calenderArr?.forEach((el, i) => {

                let mm = moment(el, 'DD/MM/YY')
                let dd = mm.toDate()

                tempArr.push({
                    moment: mm,
                    date: dd,
                    value: i + 1
                })

                if (!months.some(elx => elx.month == dd.getMonth() && elx.year == dd.getFullYear())) {
                    months.push({ month: dd.getMonth(), year: dd.getFullYear(), mm: mm })
                }

            })


            months.sort((a, b) => {
                if (a.year == b.year) {
                    return (a.month - b.month)
                }
                return (a.year - b.year)
            })

            console.log("temparr", tempArr)
            months = months.map(el => {
                let obj = { ...el }
                let newArr = tempArr.filter(elx => elx.date.getMonth() == el.month && elx.date.getFullYear() == el.year)

                newArr.sort((a, b) => a.date.getTime() - b.date.getTime())

                obj.dateArr = newArr.map(el => ({ ...el, dateI: el.date.getDate(), month: el.date.getMonth(), year: el.date.getFullYear() }))

                return obj
            })

            console.log(tempArr)
            console.log(months)

            months.forEach(el => {
                let obj = {
                    title: '',
                    weekArr: [], //  { dateArr : [{ dateString:'', value:'', enabled: false }] }
                }


                let weekObj = {
                    dateArr: []
                }

                let weekCount = 0;

                obj.title = el.mm.format('MMMM, YYYY')

                let currentDate = 1;

                let tempStartDate = new Date(el.year, el.month)

                let tempEnd = new Date(el.year, el.month + 1)
                tempEnd.setDate(tempEnd.getDate() - 1)

                let emptyCount = tempStartDate.getDay()
                for (let i = 0; i < emptyCount; i++) {
                    weekCount++;
                    weekObj.dateArr.push({ dateString: '', value: '', enabled: false })
                }
                console.log(obj.title, tempEnd.getDate(), "asf")

                while (currentDate <= tempEnd.getDate()) {

                    const dateObj = el.dateArr.find(elx => elx.dateI == currentDate && elx.month == el.month)

                    if (dateObj) {
                        weekObj.dateArr.push({ dateString: currentDate, value: dateObj?.value, enabled: true })
                    }
                    else {
                        weekObj.dateArr.push({ dateString: currentDate, value: '', enabled: false })
                    }
                    if (tempEnd.getDate() == 31 && currentDate > 25) {
                        console.log("ASDF", weekObj, currentDate)
                    }

                    weekCount++;
                    currentDate++;

                    if (weekCount >= 7) {
                        obj.weekArr.push(weekObj)
                        weekCount = 0
                        weekObj = {
                            dateArr: []
                        }
                    }


                }



                if (weekObj.dateArr.length) {
                    while (weekCount < 7) {
                        weekCount++;
                        weekObj.dateArr.push({ dateString: '', value: '', enabled: false })
                    }
                    obj.weekArr.push(weekObj)
                }



                finalMonthArr.push(obj)
            })


            setDisplayMonthArr(finalMonthArr)
            console.log("finalMonthArr", finalMonthArr)



        } catch (error) {
            console.error(error)
        }

    }, [calenderArr])

    const sendText = (text) => {
        if (sendChatToSocket) {
            sendChatToSocket(text)
        }
    }

    const displayMonth = useMemo(() => {
        let obj = {
            title: '',
            weekArr: []
        }
        if (displayMonthArr[currentMonthPrintIndex]) {
            return displayMonthArr[currentMonthPrintIndex]
        }

        return obj
    }, [currentMonthPrintIndex, displayMonthArr])

    const nextMonth = () => {
        if (currentMonthPrintIndex < displayMonthArr.length) {
            setCurrentMonthPrintIndex(prev => prev + 1)
        }

    }

    const previousMonth = () => {
        if (currentMonthPrintIndex > 0) {
            setCurrentMonthPrintIndex(prev => prev - 1)
        }
    }

    const disabledPrevious = currentMonthPrintIndex == 0
    const disabledNext = currentMonthPrintIndex == (displayMonthArr?.length - 1)

    return (
        <>
            <div className={styles.calenderContainer}>

                <div className={styles.calenderHeaderContainer}>
                    <div className={`${styles.calenderIcon} ${disabledPrevious && styles.calenderIconDisabled}`} onClick={() => !disabledPrevious && previousMonth()}>
                        <BsArrowLeft />
                    </div>
                    <div className={styles.calenderHeader}>
                        {displayMonth?.title}
                    </div>
                    <div className={`${styles.calenderIcon} ${disabledNext && styles.calenderIconDisabled}`} onClick={() => !disabledNext && nextMonth()}>
                        <BsArrowRight />
                    </div>
                </div>
                <div className={styles.calenderDateContainer}>

                    <div className={styles.calenderWeekContainer}>
                        <div className={`${styles.calenderDate} ${styles.calenderDateDisabled} ${styles.calenderDateText} `} >
                            Sun
                        </div>
                        <div className={`${styles.calenderDate} ${styles.calenderDateDisabled} ${styles.calenderDateText} `} >
                            Mon
                        </div>
                        <div className={`${styles.calenderDate} ${styles.calenderDateDisabled} ${styles.calenderDateText} `} >
                            Tue
                        </div>
                        <div className={`${styles.calenderDate} ${styles.calenderDateDisabled} ${styles.calenderDateText} `} >
                            Wed
                        </div>
                        <div className={`${styles.calenderDate} ${styles.calenderDateDisabled} ${styles.calenderDateText} `} >
                            Thu
                        </div>
                        <div className={`${styles.calenderDate} ${styles.calenderDateDisabled} ${styles.calenderDateText} `} >
                            Fri
                        </div>
                        <div className={`${styles.calenderDate} ${styles.calenderDateDisabled} ${styles.calenderDateText} `} >
                            Sat
                        </div>
                    </div>
                    {
                        displayMonth?.weekArr?.map(weekObj =>
                            <div className={styles.calenderWeekContainer}>
                                {
                                    weekObj?.dateArr.map(dateObj =>
                                        <div className={`${styles.calenderDate} ${dateObj.enabled ? 'backendBackgroundColorOnHover' : styles.calenderDateDisabled} `} onClick={() => dateObj.enabled && sendChatToSocket(`${dateObj.value}`, dateObj?.dateString + ' ' + displayMonth?.title)}>
                                            {dateObj.dateString}
                                        </div>
                                    )
                                }
                            </div>
                        )
                    }
                </div>

            </div>
        </>
    )
}
