import React, { useEffect, useState } from 'react'
import { BsArrowUpCircleFill, BsArrowUp } from "react-icons/bs";
import { MdOutlineKeyboardArrowUp} from "react-icons/md";

import styles from './widget.module.css'


export default function PopupChatBot({ popupChatArr = [], sendChatToSocket, title }) {

    const [popupMenuOpen, setPopupMenuOpen] = useState(false)

    useEffect(()=>{
        let time = setTimeout(()=>{
            setPopupMenuOpen(true)
        },300)
        return () => {
            clearTimeout(time)
        }
    },[])

    const toggleWebchat = () => {
        setPopupMenuOpen(prev => !prev)
    }

    const selectPopupItem = (str, printStr ='') => {
        setPopupMenuOpen(false)
        if (sendChatToSocket) {
            sendChatToSocket(str, printStr)
        }

    }



    return (
        <div className={`${styles.popupMenuContainer} ${popupMenuOpen ? styles.popupMenuContainerOpen : ''}`}>


            <button className={`${styles.popupMenuButton}  backendColor` } onClick={() => toggleWebchat()}> <div className={styles.centerAll} >{title} </div><div className={`${styles.popupMenuButtonIcon} ${popupMenuOpen ? styles.popupMenuButtonIconOpen : ''}`}><MdOutlineKeyboardArrowUp /></div> </button>
            <div className={`${styles.popupMenuItemContainer} ${popupMenuOpen ? styles.popupMenuOpen : ''}`}>
                <div className={styles.popupMenuItemInnerContainer} style={{ height: popupChatArr.length * 40 }}>
                    {
                        popupChatArr?.map((elText, i) =>
                            <div className={`${styles.popupMenuItem} backendColor`} onClick={() => selectPopupItem(`${title == 'Give Feedback' ? (5-i) : (i+1)}`, elText)}>
                                {elText}
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}
