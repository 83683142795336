import React, { useEffect, useState } from 'react'
import styles from "./WebChatBot.module.css";
import { getWebbotSettings, rootUrl } from './WebBotApi/webBotApi';
const logo = `${rootUrl}/content/webbot/logo.png`

export default function Button() {
    const [color, setColor] = useState("");
    useEffect(() => {
        getWebbotSettings().then((res) => {
            console.log(res?.data?.data?.color, " res in color");
            setColor(res?.data?.data?.color);
        }).catch(err => console.error(err));
    }, []);

    return (
        <>
            <style>
                {`
            .backendColor {
              color : ${color}
            }
            .backendBackgroudColor {
              background-color : ${color};
              color:#fff;
            }
            .backendBackgroundColorOnHover:hover{
              color : white;
              background-color : ${color};
            }
            `}

            </style>
            <div
                className={`${styles.mainDiv} backendBackgroudColor`}
                id="openButtonWebChatbot"
                onClick={() => window.top.postMessage('voiceoc_openWebbot', '*')}
            >


                <div className={styles.iconBox}>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            padding: 0,
                            overflow: 'hidden',
                            background: "white",
                            borderRadius: "100px",
                        }}
                    >
                        <img src={logo} height={24} width={24} />
                    </div>
                    <p className={styles.text} >
                        Chat With Us
                    </p>
                </div>
            </div>
        </>
    )

}
