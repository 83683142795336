import { customAlphabet } from "nanoid";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { BsArrowUpCircleFill, BsFileEarmarkPlus, BsFillFilePdfFill, BsThreeDotsVertical, BsFillSendFill, BsSendFill, BsSend, } from "react-icons/bs";
import { MdUndo } from 'react-icons/md'

import VoiceocLogo from "../assets/voiceoc_logo.png";

// import { GiRobotHelmet } from "react-icons/gi";
import ScrollToBottom from "react-scroll-to-bottom";
import socketIOClient from "socket.io-client";
import webBotServices, { getWebbotSettings, rootUrl } from "./WebBotApi/webBotApi";
import ResponseFunction from "./responseTemplates/ResponseFunction";
import NotificationSentSound from "./sound/SentMessageSound.mp3";

import { HiOutlineDownload } from "react-icons/hi";
import EmailModal from "./modals/EmailModal";
import LanguageModal from "./modals/LanguageModal";
import SettingModal from "./modals/SettingModal";
import DoctorNamesWidget from "./widget/DoctorNamesWidget";


import moment from "moment";
import { RiMessage2Fill } from "react-icons/ri";
import { RxCross1, RxReload, RxSpeakerLoud, RxSpeakerOff } from "react-icons/rx";
import { TfiMinus } from "react-icons/tfi";
import { ThreeDots } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { sendInitialMessage, sendOtherMessages } from "./WebBotApi/DirectWebBotApi";
import styles from "./WebChatBot.module.css";
import { handleDownload } from "./handleDownload";
import { chatInstances } from "./helpers/constants";
import TimeButtonsWidget from "./widget/TimeButtonsWidget";
import PopupChatBot from "./widget/PopupChatBot";
import CalenderWidget from "./widget/CalenderWidget";
import { IoMdAttach, IoMdClose, IoMdSend } from 'react-icons/io'
import PdfPreview from "./widget/PdfPreview";
import { Helmet } from "react-helmet";

const momentFormat = "DD MMM 'YY hh:mm a"

var socketio;
const WebChatBot = ({ id }) => {

  useEffect(() => {
    socketio = socketIOClient(rootUrl, { secure: false });
    socketio.on("connect", () => {
      console.log(socketio, "socket");
      setSocketid(socketio.id);
    });
    socketio.on("disconnect", (err) => { console.log("DISCONNECTING SOCKET", err) });
  }, []);
  const audio = useMemo(() => {
    return new Audio('/new_message_notification.mp3')
  }, [])

  const [showPopupMenu, setShowPopupMenu] = useState(false)
  const [showPopupMenuTitle, setShowPopupMenuTitle] = useState("Menu")
  const [showPopupMenuArr, setShowPopupMenuArr] = useState([])

  const [openBox, setOpenBox] = useState(true);
  const [search, setSearch] = useState("");
  const [currentMessage, setCurrentMessage] = useState("");
  const [message, setMessage] = useState("");
  const [file, setFile] = useState();
  const [type, setType] = useState("text");
  const [messageList, setMessageList] = useState([]);
  const [openSetting, setOpenSetting] = useState(false);
  const [language, setLanguage] = useState(1);
  const [openLanguageSetting, setOpenLanguageSetting] = useState(false);
  const [openEmail, setOpenEmail] = useState(false);
  const [sound, setSound] = useState(true);
  const [cordinates, setCordinates] = useState([]);
  const [doctorWidget, setDoctorWidget] = useState(false);
  const [doctorArr, setDoctorArr] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTimestamp, setIsLoadingTimestamp] = useState(0);
  const [color, setColor] = useState("");
  const [showDateWidget, setShowDateWidget] = useState(false);

  const [showCalenderWidget, setShowCalenderWidget] = useState(false)
  const [calenderArr, setCalenderArr] = useState([])

  const [resNPopup, setResNPopup] = useState(false)
  const [resNMessage, setResNMessage] = useState('')

  const [showTimeButtons, setShowTimeButtons] = useState(false);
  const [showTimeSplit, setShowTimeSplit] = useState(false);
  const [showDateCalender, setShowDateCalender] = useState(false);
  const [timeArr, setTimeArr] = useState([])
  const [showGoogleCordinatesBtn, setShowGoogleCordinatesBtn] = useState(false);
  const [socketid, setSocketid] = useState("");
  const [userId, setUserId] = useState(localStorage.getItem("uniqueUserId"));
  const [currentChatInstance, setCurrentChatInstance] = useState(chatInstances.START);

  const [unreadCount, setUnreadCount] = useState(0)

  const nanoid = customAlphabet('qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM', 15)

  const logo = `${rootUrl}/content/webbot/logo.png`
  const backgroundLogo = `${rootUrl}/content/webbot/background.png`

  const [name, setName] = useState('')
  // const scrollToBottom = useScrollToBottom()

  useEffect(() => {
    if (localStorage.getItem("uniqueUserId") === null) {
      let uniqueUserId = nanoid();
      localStorage.setItem("uniqueUserId", uniqueUserId);
    }

    setUserId(localStorage.getItem("uniqueUserId"));
  }, []);



  useEffect(() => {
    getWebbotSettings().then((res) => {
      console.log(res.data.data.color, " res in color");
      setColor(res.data.data.color);
      setName(res.data.data.name);
    }).catch(err => console.error(err));
  }, []);




  useEffect(() => {
    if ("geolocation" in navigator) {
      // console.log("Available");
    } else {
      // console.log("Not Available");
    }
  }, []);

  function formattimestamp(timestamp) {
    return moment(timestamp).format(momentFormat)
  }

  async function handleSocket(data, disableUnread = false) {
    try {
      console.log(data, "HANDLING SOCKET RESPONSE");
      if (data?.extraDataForProcessing?.is_res_n) {
        setResNPopup(true)
        setResNMessage(data.text)
        return
      }
      setResNPopup(false)

      if (data?.extraDataForProcessing?.showPopupMenu) {
        if (data?.extraDataForProcessing?.showPopupMenuText) {
          setShowPopupMenuTitle(data?.extraDataForProcessing?.showPopupMenuText)
        }
        else {
          setShowPopupMenuTitle("Menu")
        }
        if (data?.action?.sections?.length && data?.action?.sections[0]?.rows?.length) {
          setShowPopupMenuArr(data?.action?.sections[0]?.rows?.map(el => el.title))
          setShowPopupMenu(true)
        }
        else if (data?.action?.buttons?.length) {
          //restricted back button
          const filteredButtons = data.action.buttons?.filter(el => el.reply.id !== 'Back');
          const showPopupMenuArr = filteredButtons?.map(el => el.reply.title);
          setShowPopupMenuArr(showPopupMenuArr);          
          setShowPopupMenu(true)
        }
        else {
          setShowPopupMenu(false)
          setShowPopupMenuArr([])
        }
      }
      else {
        setShowPopupMenu(false)
        setShowPopupMenuArr([])
      }


      if (data.messageFrom != "user" && data.messageFrom != 'customer') {
        if (new Date().getTime() > (isLoadingTimestamp + 500)) {
          await new Promise((res, rej) => setTimeout(() => res(true), 500))
        }
      }

      setShowTimeSplit(false)
      if (data.timestamp) {
        data.printTimestamp = formattimestamp(data.timestamp)
      }
      else {
        data.printTimestamp = formattimestamp(new Date())
      }
      // if (data?.text?.includes("date of birth")) {
      //   setShowDateWidget(true);
      // } else setShowDateWidget(false);


      console.log(data?.extraDataForProcessing?.dateList?.length)
      if (data?.extraDataForProcessing?.dateList?.length) {
        setShowCalenderWidget(true);
        setCalenderArr(data?.extraDataForProcessing?.dateList)
      }
      else {
        setShowCalenderWidget(false);
        setCalenderArr([])
      }
      // else {
      //   setShowTimeButtons(false);
      // }

      console.log(data?.extraDataForProcessing?.timeList?.length)
      if (data?.extraDataForProcessing?.timeList?.length) {
        setShowTimeButtons(true);
        setTimeArr(data?.extraDataForProcessing?.timeList)
        if (data?.extraDataForProcessing?.timeSplit) {
          setShowTimeSplit(true)
        }
      }
      // else {
      //   setShowTimeButtons(false);
      // }
      else if (!data?.extraDataForProcessing?.dateList?.length) {
        setShowTimeButtons(false);
      }

      if (data?.extraDataForProcessing?.doctorList?.length) {
        setDoctorWidget(true);
        setDoctorArr(data?.extraDataForProcessing?.doctorList)
      }
      else {
        setDoctorWidget(false);
      }

      if (data?.text?.includes("share google coordinates")) {
        // setShowGoogleCordinatesBtn(true);
      } else setShowGoogleCordinatesBtn(false);


      // if (
      //   data?.messageFrom == "user" &&
      //   (data?.type == "image" ||
      //     data?.type == "document" ||
      //     data?.type == "video")
      // )
      //   setMessageList((list) => [...list]);
      // else 
      setMessageList((list) => [...list, data]);

      if (data?.messageFrom && data?.type == "text") {
        setIsLoading(true);
        setIsLoadingTimestamp(new Date().getTime())
      }
      else {
        // setIsLoading(false);
      }
      console.log("sound check????", sound)
      if (sound && !data?.messageFrom) {
        const sentNotification = new Audio(NotificationSentSound);
        sentNotification.play().catch((err) => console.log(err, "audio error"));
      }
      if (data.messageFrom != 'user' && data.messageFrom != 'customer') {
        setIsLoading(false);
      }

      console.log("??????", data.messageFrom != 'user' && !openBox, unreadCount)
      if (data.messageFrom != 'user' && data.messageFrom != 'customer' && !openBox && !disableUnread) {
        setUnreadCount(prev => prev + 1)
      }

    } catch (error) {
      console.error("ERROR", error)
    }
  }

  useEffect(() => {
    // console.log("HANDLING SOCKET")
    socketio.on("sendChat", handleSocket);
    return () => {
      socketio.off("sendChat", handleSocket);
      setIsLoading(false);
    };
  }, [socketio, sound, openBox]);



  // useEffect(() => {
  //   console.log({ timeArr, showTimeButtons })

  // }, [timeArr, showTimeButtons])




  useEffect(() => {

    let tempTimeout = null

    const getPrevChat = async () => {
      console.log("RUNNING")
      webBotServices
        .saveChatHistory(userId)
        .then((res) => {
          console.log(res.data.results, "res in getPrevChat");
          res.data.results = removeConsecutiveDuplicates(res.data.results)
          console.log(res.data.results, "after in getPrevChat arr");
          let arr = [...res.data.results.map(el =>
            ({ ...el, printTimestamp: formattimestamp(el.timestamp), type: el.text == 1 ? 'text' : '' }))
            .filter((el, i) => (el.messageFrom == 'user' || el.messageFrom == 'customer') ? true : i + 1 != res.data.results?.length)
          ]

          console.log(arr, "res in getPrevChat arr");
          setMessageList(arr);
          if (res.data.results?.length != arr?.length) {
            tempTimeout = setTimeout(() => {
              console.log("SENDING INIT", res.data.results[res.data.results?.length - 1])
              handleSocket(res.data.results[res.data.results?.length - 1], true)
            }, 100)
          }
        })
        .catch((err) => console.log(err, "error in getPrevChat"));
    };

    if (userId) {

      getPrevChat()
    }
    return () => {
      if (tempTimeout) {
        clearTimeout(tempTimeout)
        tempTimeout = null
      }
    }
  }, [userId])

  const sendEmailToBot = async (emailId) => {
    webBotServices
      .sentEmail(emailId, userId)
      .then((res) => {
        console.log(res, "res in sent email");
        setOpenSetting(false);
        setOpenEmail(false);
        toast.success("Email sent successfully");
      })
      .catch((err) => {
        console.log(err, "err in sent email to bot");
      });
  };

  const sendChatToSocket = async (data, printText = '') => {
    // scrollToBottom()
    setIsLoading(true)
    setIsLoadingTimestamp(new Date().getTime())
    setSearch("");
    setShowDateWidget(false);

    if (currentMessage.length > 0) {
      let messageData;
      if (type == "text") {
        console.log("in message type text");
        messageData = {
          text: currentMessage,
          messageType: "text",
          messageFrom: "user",
        };
      }
    }
    printText = printText ? printText : data
    let formData = new FormData();
    formData.append("file", file);
    formData.append("mobile", userId);
    formData.append("agent_id", "SMBHOSP");
    formData.append("socket_id", socketid);
    formData.append("type", type);
    formData.append("text", data);
    formData.append("printText", printText ? printText : data);

    webBotServices
      .sendMessageToBot(data, printText, type, file, formData, userId, socketid)
      .then((res) => {
        console.log(res, "ressss in su");
      })
      .catch((err) => {
        console.log(err, "error");
      })
      ;

    setCurrentMessage("");
    setMessage("");
    setFile(null);
  };

  const sendLocation = () => {
    function showError(error) {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          alert("User denied the request for Geolocation.");
          break;
        case error.POSITION_UNAVAILABLE:
          alert("Location information is unavailable.");
          break;
        case error.TIMEOUT:
          alert("The request to get user location timed out.");
          break;
        case error.UNKNOWN_ERROR:
          alert("An unknown error occurred.");
          break;
      }
    }

    function setPosition(position) {
      setCordinates([position.coords.latitude, position.coords.longitude]);
      setCurrentMessage(cordinates.toString());
      setMessage(cordinates.toString());
      setSearch(cordinates.toString());
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(setPosition, showError);
    } else alert("Geolocation is not supported by this browser");
  };


  const setDateOfBirth = (date) => {
    const yyyy = date?.getFullYear();
    let mm = date?.getMonth() + 1;
    let dd = date?.getDate();
    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    const formattedToday = dd + "/" + mm + "/" + yyyy;
    console.log(formattedToday, "fotygyu");
    setSearch(formattedToday);
    setCurrentMessage(formattedToday);
  };

  // useEffect(() => {
  //   console.log(messageList, "medtsfws hhuxhuexiru4i4r");
  // }, [messageList]);

  // useEffect(() => {
  //   console.log(file, "fileeeeeeeeeee");
  // }, [file]);

  // useEffect(() => {
  //   console.log(currentMessage.length, "3456789");
  // }, [currentMessage]);


  const handleChange = (e) => {
    setSearch(e.target.value);
    setMessage(e.target.value);
    setType("text");
    setCurrentMessage(e.target.value.trim());
  };


  const sleep = (ms) => {
    return new Promise((res, rej) => {
      setTimeout(() => res(true), ms)
    })
  }



  const handleFile = (e) => {
    if (e.target.files && e.target.files.length) {
      console.log(e.target.files[0], "files")
      setFile(e.target.files[0])
      setMessage('')
      if (e.target.files[0].type == 'application/pdf') {
        setType('document')
      }
      else if (e.target.files[0].type.includes('video')) {
        setType('video')
      }
      else {
        setType('image')
      }
    }
    else {
      setFile(null)
    }
  }

  const clearFile = () => {
    setFile(null)
    document.getElementById('file-upload').value = null;
  }



  const restartConversation = async () => {
    try {
      let tempObj = {
        message: "/restart",
        sender: userId
      }
      const { data: res } = await sendOtherMessages(tempObj);
      if (res) {
        console.log(res)
      }
    } catch (error) {
      console.error(error)
      alert(error)
    }
  }

  
const removeConsecutiveDuplicates = (messages) => {
    const filteredMessages = [];
    let prevUserMessage = null;

    for (const message of messages) {
        if (message.messageFrom === 'user') {
            const currentMessageContent = message?.printText || message.extraDataForProcessing?.printText;
            const previousUserContent = prevUserMessage?.printText || prevUserMessage?.extraDataForProcessing?.printText;
            if (!prevUserMessage || previousUserContent !== currentMessageContent) {
                // Add the user message if it's not a duplicate
                filteredMessages.push(message);
            }
            prevUserMessage = message;
        } else {
            // Bot message encountered, reset prevUserMessage
            filteredMessages.push(message);
            prevUserMessage = null;
        }
    }

    return filteredMessages;
};



  useEffect(() => {
    console.log("HERUEUREURE")
    // let newMessageList = removeConsecutiveDuplicates(messageList)
    // setMessageList(newMessageList)
    // setTimeout(()=>{window.scroll(0,1000);alert("SCROLLING")},3000)
  }, [])

  return (
    <>
      {
        color && openBox &&
        <>
          <Helmet>
            <meta name="theme-color" content={color} />
            <meta name="msapplication-TileColor" content={color} />
            <meta name="msapplication-navbutton-color" content={color} />
            <meta name="apple-mobile-web-app-status-bar-style" content={color} />
          </Helmet>
        </>
      }
      <style>


        {/* #inputStyle:hover {
               border:2px solid ${color};

            } */}
        {`

            .backendColor {
              color : ${color}
            }
            .backendBackgroudColor {
              background-color : ${color};
              color:#fff;
            }

            .backendBackgroundColorOnHover:hover{
              color : white;
              background-color : ${color};
            }

            `}
      </style>
      <div className={styles.fixedDiv}>
        <ToastContainer />
        {!openBox && (
          <div
            className={`${styles.mainDiv} backendBackgroudColor`}
            onClick={() => {
              // setOpenBox(true);
              setUnreadCount(0)
            }}
          >
            {
              !!unreadCount &&
              <div className={styles.mainDivUnread}>{unreadCount}</div>
            }


            <div className={styles.iconBox}>
              {/* <RiMessage2Fill  size={20} /> */}

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: 0,
                  overflow: 'hidden',
                  background: "white",
                  borderRadius: "100px",
                }}
              >
                <img src={logo} height={24} width={24} />
                {/* <GiRobotHelmet size={27} color={"rgba(0, 0, 0, 0.6)"} /> */}
              </div>
              <p className={styles.text} >
                Chat With Us
              </p>
            </div>
          </div>
        )}
        {
          openBox &&
          <div>

            <div className={`${styles.chatContainer} ${openBox ? styles.showBox : styles.hideBox}`}>

              {/* <div style="padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left); position:relative"> */}
              <div
                className={styles.headDiv}
                style={{ backgroundColor: color }}
              >
                <div className={styles.leftHeadDiv}>
                  {/* <BsThreeDotsVertical
                      size={25}
                      className={styles.icon}
                      onClick={() => setOpenSetting(!openSetting)}
                    /> */}
                  {openSetting && (
                    <SettingModal
                      setOpenLanguageSetting={() =>
                        setOpenLanguageSetting(true)
                      }
                      setOpenSetting={() => setOpenSetting(false)}
                      setOpenEmail={() => setOpenEmail(true)}
                      sound={sound}
                      setSound={() => {
                        setSound(!sound);
                        setOpenSetting(false);
                      }}
                    />
                  )}
                  {openLanguageSetting && (
                    <LanguageModal
                      setLanguage={(data) => setLanguage(data)}
                      language={language}
                      sendChatToSocket={(data) => sendChatToSocket(data)}
                      setOpenLanguageSetting={() =>
                        setOpenLanguageSetting(false)
                      }
                    />
                  )}
                  {openEmail && (
                    <EmailModal
                      color={color}
                      setOpenEmail={() => setOpenEmail(false)}
                      sentEmail={(emailId) => sendEmailToBot(emailId)}
                      setOpenSetting={() => setOpenSetting(false)}
                    />
                  )}
                  <div
                    style={{
                      marginLeft: "8px",
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                      overflow: 'hidden',
                      background: "white",
                      borderRadius: "100px",
                    }}
                  >
                    <img src={logo} height={35} width={35} />
                    {/* <GiRobotHelmet size={27} color={"rgba(0, 0, 0, 0.6)"} /> */}
                  </div>
                  <div style={{ marginLeft: "8px" }}>
                    <p className={styles.head}>{name}</p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "14px",
                  }}
                  className={styles.rightHeadDiv}
                >

                  <div className={styles.icon} onClick={() => setSound(prev => !prev)}>
                    {sound ? <RxSpeakerLoud size={20} /> : <RxSpeakerOff size={20} />}
                  </div>
                  <div
                    className={styles.icon}
                    onClick={() => sendChatToSocket("hi")}
                  >
                    <RxReload size={20} />
                  </div>
                  <div
                    id="closeButtonWebChatbot"
                    className={styles.icon}
                    onClick={() => window.top.postMessage('voiceoc_closeWebbot', '*')}
                  // onClick={() => setOpenBox(false)}
                  >
                    <TfiMinus size={20} />
                  </div>
                  {/* <div
                      className={styles.icon}
                      onClick={
                        messageList.length > 0
                          ? () => sendChatToSocket("clear")
                          : () => setOpenBox(false)
                      }
                    >
                      <RxCross1 size={20} />
                    </div> */}
                </div>
              </div>

              <div className={styles.upperSection}>


                {
                  resNPopup &&
                  <div className={styles.res_n_popup}>
                    <div className={styles.res_n_popup_text_container}>
                      <div>
                        {resNMessage}
                      </div>
                      <button className={styles.res_n_btn} style={{ backgroundColor: color }} onClick={() => setResNPopup(false)}>Continue</button>
                    </div>
                  </div>
                }

                <div className={styles.messageBox}>
                  <div className={styles.messageBoxBackgroundImageContainer}>
                    <img src={backgroundLogo} alt="background" className={styles.messageBoxBackgroundImage} />
                  </div>


                  <ScrollToBottom
                    className={styles.messageContainer}
                    scrollViewClassName={"customScroll"}
                    followButtonClassName={styles.followbtn}
                  >
                    {messageList.map((messageContent, index) => {

                      const userText = messageContent.printText ? messageContent.printText :
                        messageContent?.extraDataForProcessing?.printText ?
                          messageContent?.extraDataForProcessing?.printText
                          :
                          messageContent.text
                      return (
                        <div className={styles.message} key={index + '_message'}>

                          {/* <pre>
                            {messageContent && JSON.stringify(messageContent, null, 2)}
                          </pre> */}


                           {(messageContent?.messageFrom == "user" || messageContent?.messageFrom == "customer") && (messageContent.media == null || messageContent.media?.length <=0)
                            ? (
                              <div
                                id="you"
                                className={styles.messageContent}
                                style={{ backgroundColor: `${color}` }}
                              >
                                {
                                  userText?.toLowerCase() != 'hi' &&
                                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: 10, cursor: 'pointer' }} onClick={() => sendChatToSocket("back")}>
                                    <MdUndo size={15} color="rgba(255,255,255,0.7)" />
                                  </div>
                                }
                                <pre>
                                  {userText}
                                </pre>
                              </div>
                            ) : messageContent?.type == "image" ||
                              (messageContent?.messageFrom == "customer" &&
                                messageContent?.media?.[0]?.mimeType?.includes(
                                  "image"
                                )) ? (

                              <div
                                id="you"
                                className={styles.document}
                                style={{ backgroundColor: color, borderRadius: 9 }}
                              >
                                <img
                                  src={
                                    messageContent.url ||
                                    messageContent.media?.[0]?.url
                                  }
                                  id="you"
                                  className={styles.fileMessage}
                                // style={{ backgroundColor: color }}
                                />
                              </div>
                            ) : messageContent?.type == "document" ||
                            ((messageContent?.messageFrom == "customer" || messageContent?.messageFrom == "user" )&&
                                messageContent?.media?.[0]?.mimeType?.includes(
                                  "application" || "plain"
                                )) ? (
                              <div
                                id="you"
                                className={styles.document}
                                style={{ border: `2px solid ${color}`, backgroundColor: color }}
                              >
                                <PdfPreview pdfUrl={messageContent?.media?.[0]?.url || messageContent?.url} pdfName={messageContent?.media?.[0]?.name || messageContent?.filename || userText} messageFrom='user' />
                                {/* <div
                                onClick={() =>
                                  handleDownload(
                                    messageContent?.media?.[0]?.url ||
                                    messageContent?.url,
                                    messageContent?.media?.[0]?.name ||
                                    messageContent?.filename
                                  )
                                }
                              >
                                {" "}
                                <HiOutlineDownload
                                  size={"1.3rem"}
                                  style={{
                                    marginLeft: "10px",
                                    cursor: "pointer",
                                  }}
                                />
                              </div>
                              <div className={styles.pdfDiv}>
                                <BsFillFilePdfFill
                                  size={"2.6rem"}
                                  color={"red"}
                                />
                              </div>
                              <p
                                className={styles.pdftext}
                                style={{ background: color, color:'white' }}
                              >
                                {messageContent?.media?.[0]?.filename ||
                                  messageContent?.filename}
                              </p> */}
                              </div>
                            ) : messageContent?.type == "video" ||
                            ((messageContent?.messageFrom == "customer" || messageContent?.messageFrom == "user") &&
                                messageContent?.media?.[0]?.mimeType?.includes(
                                  "video"
                                )) ? (
                              <video
                                controls
                                id="you"
                                className={styles.fileMessage}
                                style={{ backgroundColor: color }}
                              >
                                <source
                                  src={
                                    messageContent.url ||
                                    messageContent.media?.[0]?.url
                                  }
                                  type="video/mp4"
                                />
                                Your browser does not support the video tag.
                              </video>
                            ) : (
                              <div id="other" className={styles.otherDiv}>
                                <ResponseFunction
                                  sendChatToSocket={sendChatToSocket}
                                  hideButtons={showPopupMenu}
                                  botresponse={messageContent}
                                  setDateOfBirth={(date) => setDateOfBirth(date)}
                                  showDateWidget={showDateWidget}
                                  showButtons={index + 1 === messageList?.length}
                                  isLast={index + 1 === messageList?.length}
                                  showGoogleCordinatesBtn={
                                    showGoogleCordinatesBtn
                                  }
                                  // sendLocation={() => {
                                  //   currentMessage?.length > 0 &&
                                  //     sendChatToSocket(cordinates.toString());
                                  // }}

                                  sendLocation={() => {
                                    sendLocation();
                                    // sendChatToSocket(cordinates.toString());
                                  }}
                                  color={color}
                                  number="1"
                                  btnFun={(data, val = '') => {
                                    sendChatToSocket(data, val);
                                  }}
                                />
                              </div>
                            )}
                          <div className={`${styles.timestamp} ${(messageContent?.messageFrom == "user" || messageContent?.messageFrom == "customer")  && styles.usertimestamp}`}>{messageContent.printTimestamp}</div>
                        </div>
                      );
                    })}
                    <div className={styles.messageBottomMargin} >

                      {
                        showTimeButtons && (
                          <TimeButtonsWidget
                            timeArr={timeArr}
                            timeSplit={showTimeSplit}
                            sendChatToSocket={(data, val = '') => sendChatToSocket(data, val)}
                            setShowTimeButtons={() => setShowTimeButtons(false)}
                          />
                        )
                      }
                      {
                        showCalenderWidget &&
                        <div >
                          <CalenderWidget
                            calenderArr={calenderArr}
                            sendChatToSocket={(data, alt = "") => sendChatToSocket(data, alt)}
                          />
                        </div>
                      }
                      {doctorWidget && (
                        <div style={{ overflow: "hidden" }}>
                          <DoctorNamesWidget
                            doctorArr={doctorArr}
                            sendChatToSocket={(data, val = '') => sendChatToSocket(data, val)}
                            setDoctorWidget={() => setDoctorWidget(false)}
                          />
                        </div>
                      )}

                      {isLoading && (
                        <ThreeDots
                          height="35"
                          width="35"
                          radius="9"
                          color={color}
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{ marginLeft: 10 }}
                          wrapperClassName=""
                          visible={true}
                        />
                      )}

                      {/* <div className={styles.spacer_div}></div> */}
                      {/* {showPopupMenu && <div style={{ height: 24, width: '100%' }}></div>} */}
                    </div>
                  </ScrollToBottom>
                </div>
              </div>


              <div className={styles.position_relative} style={{ backgroundColor: 'white', }}>

                {showPopupMenu && <PopupChatBot popupChatArr={showPopupMenuArr} sendChatToSocket={sendChatToSocket} title={showPopupMenuTitle} />}

                <div className={styles.search} id="inputStyle">
                  <input
                    // onClick={() => {
                    //   // document.getElementById('inputmain')?.focus()
                    //   setTimeout(() => {
                    //     window.scrollY(1000);
                    //     alert("SCROLLING DOWN")
                    //     // document.getElementById('inputmain')?.focus()
                    //   },1000)
                    // }}
                    placeholder="Type here..."
                    id="inputmain"
                    onChange={(e) => handleChange(e)}
                    style={{ flex: 1, fontWeight: 'bold' }}
                    value={file ? file.name : message}
                    disabled={file}
                    onKeyPress={(event) => {
                      event.key === "Enter" &&
                        currentMessage.length > 0 &&
                        // sendMessageToBot()
                        sendChatToSocket(currentMessage);

                    }}
                  />

                  <input
                    type="file"
                    accept="application/pdf,image/*,video/*"
                    id="file-upload"
                    hidden
                    style={{ marginRight: 5 }}
                    // value={file ? file.name : '' }
                    onChange={(e) => {
                      handleFile(e);
                    }}
                  />

                  {
                    file &&
                    <button type="button" className={styles.sendBtn} onClick={() => clearFile()}>
                      <IoMdClose color={color} />
                    </button>
                  }


                  <label for="file-upload" className={styles.sendBtn}>
                    <IoMdAttach color={color} />
                  </label>
                  <button
                    className={styles.sendBtn}
                    disabled={currentMessage.length == 0 && !file}
                    onClick={() =>
                      (currentMessage.length > 0 || file) &&
                      sendChatToSocket(currentMessage)
                    }
                  >
                    <BsSend
                      size={18}
                      color={
                        (currentMessage.length > 0 || file)
                          ? color
                          : "#dadada"
                      }
                    />
                  </button>
                </div>
                <div className={styles.poweredBy}>
                  <a href="https://www.voiceoc.com" target="_blank">
                    <b>Powered By <span style={{ color: 'rgba(22,71,255,0.8)', textDecoration: 'none' }} >Voiceoc</span></b>
                  </a>
                </div>
              </div>

              {/* </div> */}
            </div>

          </div>
        }
      </div>
    </>
  );
};

export default WebChatBot;
