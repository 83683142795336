import WebChatBot from "./web-bot/WebChatBot";
import './App.css'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Button from "./web-bot/Button";


const router = createBrowserRouter([
  {
    path: "/",
    element: <WebChatBot />,
  },
  {
    path: "/button",
    element: <Button />,
  }
]);


function App() {
  return (
    <div>
      <RouterProvider router={router} />
      
    </div>
  );
}

export default App;
