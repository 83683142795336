import React, { useState } from "react";
import styles from "./setting.module.css";
import { RxCross1 } from "react-icons/rx";
import { AiOutlineMail } from "react-icons/ai";

import { HiOutlineLanguage } from "react-icons/hi2";
import { RxSpeakerLoud, RxSpeakerOff } from "react-icons/rx";
import LanguageModal from "./LanguageModal";

const SettingModal = ({
  setOpenSetting,
  setOpenLanguageSetting,
  setOpenEmail,
  sound,
  setSound,
}) => {
  return (
    <div className={styles.modalDiv}>
      <div className={styles.headDiv}>
        <h2 className={styles.heading}>Settings</h2>
        <div className={styles.cross} onClick={setOpenSetting}>
          <RxCross1 size={18} color="rgba(0, 0, 0, 0.6)" />
        </div>
      </div>

      <div className={styles.content}>
        <div className={styles.row} onClick={setSound}>
          {sound ? <RxSpeakerOff size={18} /> : <RxSpeakerLoud size={18} />}
          <p className={styles.text}>
            {sound ? "Turn off sound" : "Turn on sound"}
          </p>
        </div>

        {/* <div className={styles.row} onClick={setOpenLanguageSetting}>
          <HiOutlineLanguage size={22} />
          <p className={styles.text}>Change language</p>
        </div> */}

        {/* <div className={styles.row} onClick={setOpenEmail}>
          <AiOutlineMail />
          <p className={styles.text}>Email transcript</p>
        </div> */}
      </div>
    </div>
  );
};

export default SettingModal;
