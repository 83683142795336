import axios from "axios";

export const rootUrl = process.env.REACT_APP_baseUrl;



const BaseURL = `${rootUrl}/api/v1/`;

// let mobile = "5456551454";

async function sendMessageToBot(
  data,
  printText,
  messageType,
  file,
  formData,
  userId,
  socketid
) {
  // console.log(userId, "user id in send message to bot");
  // console.log(file, "file  in axios");
  // console.log(messageType, "messageType");
  const headers = {
    accept: "multipart/form-data; boundary=<calculated when request is sent>",
  };

  const url = `${BaseURL}admins/webBot/response`;

  let response = await axios.post(
    url,
    file
      ? formData
      : {
        // agent_id: "SMBHOSP",
        socket_id: socketid,
        mobile: userId,
        type: messageType,
        text: data,
        printText: printText,
      },
    {
      headers,
    }
  );

  return response;
}

async function saveChatHistory(userId) {
  // console.log(userId, "user id in save chat history");
  let params = {
    mobile: userId,
  };
  const headers = {
    timestamp: Date.now().toString(),
  };
  const url = `${BaseURL}admins/webbot/chatList?timestamp=${new Date().getTime()}`;
  let response = await axios.get(url, { params });
  return response;
}

async function sentEmail(emailId, userId) {
  const url = `${BaseURL}admins/webbot/chatDownload`;
  const headers = {
    accept: "Application/json",
  };
  let response = await axios.post(
    url,
    { email: emailId, mobile: userId },
    headers
  );
  return response;
}

async function getBotColor(id) {
  // console.log(id, "idddd");
  const url = `${BaseURL}/admins/webBot/getWebBotColor`;
  let response = await axios.get(url);
  return response;
}


export async function getWebbotSettings() {
  const url = `${BaseURL}/admins/webBot/getWebbotSettings`;
  let response = await axios.get(url);
  return response;
}

export async function setWebbotSettings(obj) {
  const url = `${BaseURL}/admins/webBot/setWebbotSettings`;
  let response = await axios.post(url, obj);
  return response;
}



export default { sendMessageToBot, saveChatHistory, sentEmail, getBotColor };
